import React from 'react'
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import Timeline from '../../../../components/ui/timeline-ru'
import ImageOne from '../../../../data/images/bg/machines/filtration/solder-small-filtration.jpg'
import ImageTwo from '../../../../data/images/bg/machines/filtration/solder-big-filtration.jpg'
import ImageThree from '../../../../data/images/bg/machines/filtration/laser-filtration.jpg'
import { SectionWrap } from './style'


const TimelineArea = (props) => {
    const content = [
        {
            year: "АВТОНОМНЫЕ ВЫТЯЖКИ",
            image: ImageOne,
            title: "Дымоулавливающие системы BOFA",
            desc: "Локальные вытяжные системы для оснащения 1-3 рабочих мест. Имеют трехступенчатую систему очистки воздуха от механических части и вредных газов образующихся во время пайки. Системы включают в себя комбинированные фильтры типа HEPA/GAS и предварительный фильтр. Оборудование имеет удобней доступ к фильтрам и индикатор состояния фильтров в базовой комплектации",
            path: "/оборудование-для-монтажа/дымоулавливающие-системы/дымоуловители-для-пайки",
            lang: "Подробно..."
        },
        {
            year: "МНОГОМЕСТНЫЕ ВЫТЯЖКИ",
            image: ImageTwo,
            title: "Дымоулавливающие системы BOFA",
            desc: "Многоместные дымоулавливающие системы позволят оснастить несколько рабочих мест используя один блок фильтрации, что уменьшает затраты для замены фильтров. Оборудование оснащено фильтрами повышенной емкости и регулировкой скорости потока, индикацией состоянии фильтра и встроенными шумогасителями. Оборудование может обслуживать большое кол-во монтажных мест (20) с одним блоком фильтрации или применятся для фильтрации загрязнений от автоматического оборудования для пайки (паяльные печи, оборудование для пайки волной и оборудование для селективной пайки).",
            path: "/оборудование-для-монтажа/дымоулавливающие-системы/вытяжки-для-пайки",
            lang: "Подробно..."
        },
        {
            year: "ВЫТЯЖКИ ДЛЯ ЛАЗЕРА",
            image: ImageThree,
            title: "Дымоулавливающие системы BOFA",
            desc: "Интеллектуальные очистные системы BOFA для улавливания вредных веществ, которые образуются во время работы оборудования для лазерной маркировки и лазерной резки. Оборудование оснащено фильтрами повышенной емкости и регулировкой скорости потока, индикацией состоянии фильтра и встроенными шумогасителями. Вытяжные системы имеет надежную 3-х ступенчатую систему очистки которая соответствует санитарным нормам и международным стандартам безопасности.",
            path: "/оборудование-для-монтажа/дымоулавливающие-системы/вытяжки-для-лазерных-станков",
            lang: "Подробно..."
        }
    ]
    const contentua = [
        {
            year: "АВТОНОМНІ ВИТЯЖКИ",
            image: ImageOne,
            title: "Димоуловлювальні системи BOFA",
            desc: "Локальні витяжні системи для оснащення 1-3 робочих місць. Мають триступеневу систему очищення повітря від механічних частин і шкідливих газів, що утворюються під час паяння. Системи містять комбіновані фільтри типу HEPA/GAS і попередній фільтр. Устаткування має зручніший доступ до фільтрів та індикатор стану фільтрів у базовій комплектації.",
            path: "/ua/обладнання-для-монтажу/системи-уловлювання-диму/уловлювання-диму-для-паяння",
            lang: "Докладно..."
        },
        {
            year: "БАГАТОМІСНІ ВИТЯЖКИ",
            image: ImageTwo,
            title: "Димоуловлювальні системи BOFA",
            desc: "Багатомісні димоуловлювальні системи дозволять оснастити кілька робочих місць використовуючи один блок фільтрації, що зменшує витрати на заміну фільтрів. Устаткування оснащене фільтрами підвищеної ємності та регулюванням швидкості потоку, індикацією стану фільтра та вбудованими шумогасниками. Устаткування може обслуговувати велику кількість монтажних місць (20) з одним блоком фільтрації або застосовуються для фільтрації забруднень від автоматичного обладнання для паяння (паяльні печі, обладнання для паяння хвилею та обладнання для селективного паяння).",
            path: "/ua/обладнання-для-монтажу/системи-уловлювання-диму/витяжки-для-паяння",
            lang: "Докладно..."
        },
        {
            year: "ВИТЯЖКИ ДЛЯ ЛАЗЕРА",
            image: ImageThree,
            title: "Димоуловлювальні системи BOFA",
            desc: "Інтелектуальні очисні системи BOFA для уловлювання шкідливих речовин, що утворюються під час роботи обладнання для лазерного маркування та лазерного різання. Устаткування оснащене фільтрами підвищеної ємності та регулюванням швидкості потоку, індикацією стану фільтра та вбудованими шумогасниками. Витяжні системи має надійну 3-ступінчасту систему очищення, яка відповідає санітарним нормам та міжнародним стандартам безпеки.",
            path: "/ua/обладнання-для-монтажу/системи-уловлювання-диму/витяжки-для-лазерних-верстатів",
            lang: "Докладно..."
        }
    ]
    if (props.lang === "ru") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Для предприятий занимающихся производством прототипов, предприятий мелкосерийного производства и мастерских не всегда есть возможность инвестировать в средства в дорогостоящую вытяжную вентиляцию.  Автономные фильтрующие системы  позволяют  решить задачу отвода вредных веществ, что возникают во время пайки и обеспечить охрану здоровья персонала. Автономные вытяжные системы задерживаю механические частицы и вредные газы, обеспечивая механическую и химическую очистку воздуха в рабочей зоне монтажников примененяя комбинированные фильтры с трехступенчатой системой фильтрации.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={content} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    } else if (props.lang === "uk") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Для підприємств, що займаються виробництвом прототипів, підприємств дрібносерійного виробництва та майстерень, не завжди є можливість інвестувати у кошти в дорогу витяжну вентиляцію. Автономні фільтруючі системи дозволяють вирішити завдання відведення шкідливих речовин, що виникають під час паяння та забезпечити охорону здоров'я персоналу. Автономні витяжні системи затримую механічні частинки та шкідливі гази, забезпечуючи механічне та хімічне очищення повітря в робочій зоні монтажників застосовуючи комбіновані фільтри з триступеневою системою фільтрації.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={contentua} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    }
}

export default TimelineArea
